export const MESSAGE_TYPE = {
  ADD_NODE_CHANNEL: "addChannel",
  CHANNEL_STATUS: "channelStatus",
  CLIENT_AUTHORIZATION: "clientAuth",
  ERROR: "error",
  ERROR_PLATFORM: "errorPlatform",
  FINGERPRINT: "getFingerprint",
  GET_NODE_CHANNELS: "getAllChannels",
  GET_CAPABILITIES: "getCapabilities",
  GET_HOST_NAME: "getHostname",
  GET_DEVICES: "getDevices",
  GET_FORMATS: "getFormats",
  GET_LOGS: "getLogs",
  GET_NODES: "getNodes",
  SET_NODES_ORDER: "setNodesOrder",
  LOG_LEVEL: "logLevel",
  NETWORK_INTERFACES: "getInterfaces",
  NEW_NODE_CHANNEL_LOG: "log",
  NODE_CHANNEL_STATISTIC: "statistic",
  NODE_CHANNEL_STATISTIC_V5: "statisticV5",
  NODE_LICENSE: "updateLicenses",
  NODE_STATS: "nodeStats",
  NODE_UPDATE_STATUS: "nodeUpdateStatus",
  PROBE_STREAM: "probeStream",
  SET_HIST_STATS_IN_MIN: "statsInMin",
  SET_HIST_STATS_IN: "statsIn",
  SET_HIST_STATS_OUT_INIT: "statsOutInit",
  SET_HIST_STATS_OUT_MIN: "statsOutMin",
  SET_HIST_STATS_OUT: "statsOut",
  SET_HOSTNAME: "setHostname",
  SET_CAPABILITIES: "nodeCapabilities",
  SHORT_FINGERPRINT: "getShortFingerprint",
  STUND_ADDRESS: "getStunAddr",
  SUCCESS: "success",
  UPDATE_CHANNEL: "updateChannel",
  UPDATE_CHANNELS: "updateChannels",
  UPDATE_LOGS: "updateLogs",
  WARNING: "warning",
  WARNING_PLATFORM: "warningPlatform",
  CLIENT_UPDATE_STATUS: "clientwebUpdateStatus",
  GET_LICENSES: "getLicenses",
  DELETE_LOGS_FROM_DB: "deleteLogsFromDb",
  KILL_CHANNEL: "killChannel",
  STREAM_SET_BACKUP: "streamSetBackup",
  DELETE_CHANNEL: "deleteChannel",
  STREAM_PLAY: "streamPlay",
  STREAM_STOP: "streamStop",
  DELETE_LICENSE: "deleteLicense",
  FORCE_DOWNLOAD_LICENSES: "forceDownloadLicenses",
  GET_STATS_IN_MIN: "getStatsInMin",
  GET_STAT_OUT_MIN: "getStatsOutInit",
  GET_STATS_OUT: "getStatsOut",
  GET_STATS_IN: "getStatsIn",
  GET_STATS_OUT_MIN: "getStatsOutMin",
  RESET_LIVE_STATS: "resetLiveStats",
  GET_LOG_LEVEL: "getLogLevel",
  SET_LOG_LEVEL: "setLogLevel",
  CONNECT_TO_CLOUD: "connectToCloud",
  LIST_NDI_CHANNELS: "listNdiChannels",
  GET_ALL_PORTS: "getAllPorts",
  UPDATE_PORTS: "updatePorts",
  QS_METHOD_UNDO: "qsMethodUndo",
  CLOUD_NODE_LICENSES: "cloudNodesLicenses",
  CLOUD_NODE_STATUS: "cloudNodeStatus",
  SETUP_LOCAL_USER: "setupLocalUser",
  USER_PASSWORD_SET_UP: "userPasswordSetUp",
  USER_PASSWORD_SET_UP_FAILURE: "userPasswordSetUpFailure",
  GET_NODES_NETWORK_USAGE: "getNodesNetworkUsage",
  UPDATE_STRIPE_CUSTOMER: "updateStripeCustomer",
  STREAM_SET_SOURCE: "streamSetSource",
  LIST_EMERGENCY_IMAGES_FILES: "listEmergencyImageFiles",
  LIST_EMERGENCY_VIDEO_FILES: "listEmergencyVideoFiles",
  GET_STAT_PARAM_NAMES: "getStatParamNames",
  GET_STAT_VALUES: "getStatValues",
  GET_LOG_PARAM_NAMES: "getLogParamNames",
  REQUEST_LIVE_STATS: "requestLiveStats",
  NODE_ALERTS_UPDATE: "alertsUpdate",
  RESET_NODE_ALERTS: "resetAlerts",
  NODE_MUTE_ALERTS: "muteAlerts",
  SET_CLOUD_CONFIG: "setCloudConfig",
  GET_CLOUD_CONFIG: "getCloudConfig",
  PLAYOUT_DATA: "playoutData",
  REQUEST_PLAYOUT_DATA: "requestPlayoutData",
};

export const NODE_CONFIG_DATA = {
  CHANGE_INPUT_SWITCH_CONFIRMATION: "changeInputSwitchConfirmation",
};

export const UNDEFINED = "undefined";

export const DEFAULT_NODE_VERSION = "3";

export const LICENSES_TYPES = {
  STANDARD: "standard",
  PROFESSIONAL: "professional",
  ENTERPRISE: "enterprise",
  FREE: "free-Quickstream",
  PLAYOUT: "playout",
  CLOUD: "cloud-license",
};

export const ROLE = {
  ADMIN: "admin",
  USER: "user",
  SUPPORT: "support",
};

export const PERMANENT_LICENSE = "never";
export const NODE = "Node";
export const NODES_URL = "node";
export const TURNSTILE_ERROR = 406;
export const TWO_FACTOR_AUTH_ERROR = 499;
export const UNAUTHORIZED_ERROR = 402;
export const AUTO = {
  value: "automatic",
  label: "Automatic",
};
export const NEW = "new";

export const INPUT_STATUS_FILTERS = [
  {
    text: "CLOSING",
    value: "CLOSING",
  },
  {
    text: "CONNECTED",
    value: "CONNECTED",
  },
  {
    text: "CONNECTING",
    value: "CONNECTING",
  },
  {
    text: "CONFIG INCOMPLETE",
    value: "CONFIG_INCOMPLETE",
  },
  {
    text: "ERROR",
    value: "ERROR",
  },
  {
    text: "PAUSED",
    value: "PAUSED",
  },
  {
    text: "RECONNECTING",
    value: "RECONNECTING",
  },
  {
    text: "REINITIALIZING",
    value: "PROCESS_DISCONNECTED",
  },
  {
    text: "STOPPED",
    value: "STOPPED",
  },
  {
    text: "UNCONFIGURED",
    value: "UNCONFIGURED",
  },
];

export const SELECT_STREAM_TYPE = [
  {
    value: "pid",
    label: "PID",
  },
  {
    value: "automatic",
    label: "Automatic",
  },
];

export const NODE_DEVICES_TYPE = {
  decklinkInputs: "decklinkInputs",
  decklinkOutputs: "decklinkOutputs",
  webcamAudioInputs: "webcamAudioInputs",
  webcamVideoInputs: "webcamVideoInputs",
};

export const MUXER_TYPES = [
  {
    value: "mpegts",
    label: "MPEGTS",
  },
];

export const OUTPUTS_TYPE = {
  nonMuxedOutputs: "nonMuxedOutputs",
  muxedOutputs: "muxedOutputs",
  DIRECT: "direct",
  TRANSCODING: "transcoding",
};

export const VIRTUAL_CHANNEL_PERMISSION = "virtualChannelPermission";

export const NODE_STATS_TYPE = {
  incoming: "incoming",
  outgoing: "outgoing",
};

export const STREAMS_TYPE = [
  {
    value: "video",
    label: "Video",
  },
  {
    value: "audio",
    label: "Audio",
  },
];

export const OUTPUTS_TYPES = {
  outputUrl: {
    value: "outputUrl",
    label: "STREAM",
  },
  outputNdi: {
    value: "outputNdi",
    label: "NDI",
  },
  outputRtmp: {
    value: "outputRtmp",
    label: "RTMP",
  },
  outputDecklink: {
    value: "outputDecklink",
    label: "SDI",
  },
};

export const PLAYOUT_OUTPUT_TYPES = {
  playoutUrl: { label: "STREAM", value: "playoutUrl" },
  playoutDecklink: {
    label: "SDI",
    value: "playoutDecklink",
  },
};

export const OUTPUT_NDI_PIXEL_FORMATS = ["UYVY422", "BGRA", "BGR0", "RGB0"];

export const INPUT_TYPES = {
  inputUrl: {
    value: "inputUrl",
    label: "STREAM",
  },
  inputDecklink: {
    value: "inputDecklink",
    label: "SDI",
  },
  inputWebcam: {
    value: "inputWebcam",
    label: "WEBCAM",
  },
  inputRtmp: {
    value: "inputRtmp",
    label: "RTMP",
  },
  inputMpegtsOverRtp: {
    value: "inputMpegtsOverRtp",
    label: "RTP",
  },
  inputNdi: {
    value: "inputNdi",
    label: "NDI",
  },
};

export const INPUT_URL_TYPE = {
  value: "inputUrl",
  label: "STREAM",
};

export const INPUT_STREAM_TYPE = {
  backup: "backup",
  main: "main",
  emergency: "emergency",
};

export const INPUT_DECKLINK_TYPE = {
  value: "inputDecklink",
  label: "SDI",
};

export const SELECT_DEFAULT_AUTO = {
  value: "automatic",
  label: "Automatic",
};

export const INPUT_WEBCAM_TYPE = {
  value: "inputWebcam",
  label: "WEBCAM",
};

export const NODE_CHANEL_TABS = {
  channelList: "channelList",
  logs: "logs",
  statistics: "statistics",
  ports: "ports",
  search: "search",
};

export const NODE_CHANEL_DETAIL_VIEW_TABS = {
  configurations: "configurations",
  logs: "logs",
  statistics: "statistics",
  playlist: "playlist",
};

export const MQTT_STATUS = {
  ONLINE: "online",
  OFFLINE: "offline",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  TRUE: true,
  FALSE: false,
};

export const QUICKSTREAM = "quickstream";

export const LOADING_DATA_TYPE = {
  getCapabilities: "getCapabilities",
  getNodeChannels: "getAllChannels",
  getHostname: "getHostname",
  logFileList: "getLogFilesList",
  logLevel: "getLogLevel",
  networkInterfaces: "getInterfaces",
  updateNodeChannel: "updateNodeChannel",
  nodeLicenseList: "nodeLicenseList",
};

export const NODE_CHANNEL_STATE = {
  CONNECTED: "CONNECTED",
  CONNECTING: "CONNECTING",
  ERROR: "ERROR",
  EMPTY: "EMPTY",
  STOPPED: "STOPPED",
  RECONNECTING: "RECONNECTING",
  PAUSED: "PAUSED",
  PROCESS_DIED: "PROCESS_DIED",
  UNCONFIGURED: "UNCONFIGURED",
  CONFIG_INCOMPLETE: "CONFIG_INCOMPLETE",
  PROCESS_DISCONNECTED: "PROCESS_DISCONNECTED",
  STARTED: "STARTED",
};

export const AUTO_IP = "automatic";

export const INVALID_CONFIG = "invalid";
export const QUICKSTREAM_METHOD_V2 = {
  inQSDirect: {
    value: "inQSDirect",
    label: "Quickstream",
  },
  outQSDirect: {
    value: "outQSDirect",
    label: "Quickstream",
  },
};

export const DEFAULT_VIRTUAL_CHANNEL = {
  languages: [],
  subtitles: [],
  resolutions: [],
  videoCodec: [],
};

export const CONNECTION_TYPE = {
  NODE_DIRECT: "nodeDirect",
  NODE_CLOUD: "nodeCloud",
  EXTERNAL_CLOUD: "externalCloud",
  EXTERNAL_DIRECT: "externalDirect",
};

export const CONNECTION_METHOD_INPUT = {
  inUdpMulticast: {
    value: "inUdpMulticast",
    label: "UDP Multicast",
  },
  inUdpUnicast: {
    value: "inUdpUnicast",
    label: "UDP Unicast",
  },
  inSrtListener: {
    value: "inSrtListener",
    label: "SRT Listener",
  },
  inSrtCaller: {
    value: "inSrtCaller",
    label: "SRT Caller",
  },
};

export const CONNECTION_METHOD_OUTPUT = {
  outUdp: {
    value: "outUdp",
    label: "UDP",
  },
  outSrtListener: {
    value: "outSrtListener",
    label: "SRT Listener",
  },
  outSrtCaller: {
    value: "outSrtCaller",
    label: "SRT Caller",
  },
};

export const UDP_OUTPUT_METHOD = {
  value: "udpMulticast",
  label: "UDP",
};

export const DEMO_CONNECTION_METHOD = [
  {
    value: "sdi",
    label: "SDI",
  },
  {
    value: "usbCamera",
    label: "USB Camera",
  },
];

export const CHANNEL_SOURCE = {
  nodeChannel: 0,
};

export const LOGS_TYPE = {
  all: "-1",
};

export const DEFAULT_VALUES = {
  LOCALPORT_INPUT_UNICAST: 1234,
  LOCALPORT: 0,
  PORT: 1234,
  IP: "0.0.0.0",
  OVERHEAD: 25,
  TTL: 8,
  TRIGGER_INIT: false,
  TRIGGER_BITRATE_LEVEL: 1024,
  TRIGGER_BITRATE_SECS: 5,
  TRIGGER_BITRATE_TIME_SECS: 10,
  SRT_LATENCY: 1000,
  SWITCH_INPUT_BACKUP: false,
};

export const SOURCE_TYPE = [
  {
    value: "multicast",
    label: "Multicast",
  },
  {
    value: "unicast",
    label: "Unicast",
  },
];

export const SRT_MODES = [
  {
    value: "listener",
    label: "Listener",
  },
  {
    value: "caller",
    label: "Caller",
  },
  {
    value: "rendezvous",
    label: "Rendezvous",
  },
];

export const CLOUD_CHANNEL_TYPE = {
  all: "all",
  own: "own",
};

export const SKIP_QS_METHOD_ERROR = 467;

export const STATISTIC_OUTPUT_TYPE = [
  "mbpsBandwidth",
  "msRtt",
  "ccErrors",
  "ccErrorsPerHour",
  "ccErrorsTotal",
  "pktSent",
  "pktSndLoss",
  "pktSndDrop",
  "pktRetrans",
  "mbpsSendRate",
];

export const STATISTIC_INPUT_TYPE = [
  "mbpsBandwidth",
  "msRtt",
  "ccErrors",
  "ccErrorsPerHour",
  "ccErrorsTotal",
  "pktRecv",
  "pktRcvLoss",
  "pktRcvDrop",
  "pktRcvRetrans",
  "pktRcvBelated",
  "mbpsRecvRate",
];

export const HIST_STATS_TYPE = {
  input: "input",
  output: "output",
};

export const DEFAULT_INPUT_TYPE = "inputUrl";
export const FORM_VERSION = "4.0";
export const FORM_VERSION_V5 = "5.0";
export const PROTOCOL_VERSION = "4.0";
export const PROTOCOL_VERSION_V5 = "5.0";
export const INPUT_BACKUP_TYPE = "backup";
export const OUTPUT_TYPE = "nonMuxedOutputs";

export const SELECT_STREAM_CODEC_FPS = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "23.98",
    label: "23.98 [fps]",
  },
  {
    value: "24",
    label: "24 [fps]",
  },
  {
    value: "25",
    label: "25 [fps]",
  },
  {
    value: "29.97",
    label: "29.97 [fps]",
  },
  {
    value: "30",
    label: "30 [fps]",
  },
  {
    value: "47.95",
    label: "47.95 [fps]",
  },
  {
    value: "48",
    label: "48 [fps]",
  },
  {
    value: "50",
    label: "50 [fps]",
  },
  {
    value: "59.94",
    label: "59.94 [fps]",
  },
  {
    value: "60",
    label: "60 [fps]",
  },
  {
    value: "95.90",
    label: "95.90 [fps]",
  },
  {
    value: "96",
    label: "96 [fps]",
  },
  {
    value: "100",
    label: "100 [fps]",
  },
  {
    value: "119.88",
    label: "119.88 [fps]",
  },
  {
    value: "120",
    label: "120 [fps]",
  },
];

export const NDI_SELECT_FPS = [
  {
    value: "23.98",
    label: "23.98 [fps]",
  },
  {
    value: "24",
    label: "24 [fps]",
  },
  {
    value: "25",
    label: "25 [fps]",
  },
  {
    value: "29.97",
    label: "29.97 [fps]",
  },
  {
    value: "30",
    label: "30 [fps]",
  },
  {
    value: "47.95",
    label: "47.95 [fps]",
  },
  {
    value: "48",
    label: "48 [fps]",
  },
  {
    value: "50",
    label: "50 [fps]",
  },
  {
    value: "59.94",
    label: "59.94 [fps]",
  },
  {
    value: "60",
    label: "60 [fps]",
  },
  {
    value: "95.90",
    label: "95.90 [fps]",
  },
  {
    value: "96",
    label: "96 [fps]",
  },
  {
    value: "100",
    label: "100 [fps]",
  },
  {
    value: "119.88",
    label: "119.88 [fps]",
  },
  {
    value: "120",
    label: "120 [fps]",
  },
];

export const SAMPLE_RATES_SELECT = [
  {
    value: "96000",
    label: "96000",
  },
  {
    value: "88200",
    label: "88200",
  },
  {
    value: "64000",
    label: "64000",
  },
  {
    value: "48000",
    label: "48000",
  },
  {
    value: "44100",
    label: "44100",
  },
  {
    value: "32000",
    label: "32000",
  },
  {
    value: "24000",
    label: "24000",
  },
  {
    value: "22050",
    label: "22050",
  },
  {
    value: "16000",
    label: "16000",
  },
  {
    value: "12000",
    label: "12000",
  },
  {
    value: "11025",
    label: "11025",
  },
  {
    value: "8000",
    label: "8000",
  },
  {
    value: "7350",
    label: "7350",
  },
];

export const VIDEO_CODECS = ["MPEG-2", "H.264/AVC", "VP9", "H.265/HEVC", "AV1", "H.266/VVC"];

export const SELECT_STREAM_CODEC = [
  {
    value: "Copy",
    label: "Copy",
  },
  {
    value: "Mpeg2video",
    label: "MPEG-2 Video",
  },
  {
    value: "OpenH264",
    label: "OPENH264",
  },
  {
    value: "X264",
    label: "H.264",
  },
];

export const SELECT_STREAM_CODEC_AUDIO = [
  {
    value: "Copy",
    label: "Copy",
  },
  {
    value: "Mpeg2audio",
    label: "MPEG-2 Audio Layer II",
  },
  {
    value: "Aac",
    label: "AAC",
  },
];

export const SELECT_STREAM_CODEC_RESIZE = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "pad",
    label: "Pad",
  },
  {
    value: "crop",
    label: "Crop",
  },
  {
    value: "stretch",
    label: "Stretch",
  },
  {
    value: "fitWidth",
    label: "Fit Width",
  },
  {
    value: "fitHeight",
    label: "Fit Height",
  },
];
export const AAC_SAMPLE_FORMAT = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "FLTP",
    label: "FLTP",
  },
];

export const AAC_SAMPLE_RATE = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "44100",
    label: "44.1kHz",
  },
  {
    value: "48000",
    label: "48kHz",
  },
];

export const AAC_PROFILE = [
  {
    value: "aac_low",
    label: "Low Complexity AAC (LC)",
  },
  {
    value: "aac_he",
    label: "High Efficiency AAC (HE-AAC)",
  },
  {
    value: "aac_he_v2",
    label: "High Efficiency AAC version 2 (HE-AACv2)",
  },
  {
    value: "aac_ld",
    label: "Low Delay AAC (LD)",
  },
  {
    value: "aac_eld",
    label: "Enhanced Low Delay AAC (ELD) ",
  },
];

export const MPEG2AUDIO_SAMPLE_FORMAT = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "FLT",
    label: "FLT",
  },
  {
    value: "FLTP",
    label: "FLTP",
  },
  {
    value: "S16",
    label: "S16",
  },
  {
    value: "S16P",
    label: "S16P",
  },
];

export const MPEG2AUDIO_SAMPLE_RATE = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "44100",
    label: "44.1kHz",
  },
  {
    value: "48000",
    label: "48kHz",
  },
];

export const MPEG2AUDIO_BITRATE = [
  {
    value: 0,
    label: "Automatic",
  },
  {
    value: 64,
    label: "64 [kbps]",
  },
  {
    value: 80,
    label: "80 [kbps]",
  },
  {
    value: 96,
    label: "96 [kbps]",
  },
  {
    value: 112,
    label: "112 [kbps]",
  },
  {
    value: 128,
    label: "128 [kbps]",
  },
  {
    value: 160,
    label: "160 [kbps]",
  },
  {
    value: 192,
    label: "192 [kbps]",
  },
  {
    value: 224,
    label: "224 [kbps]",
  },
  {
    value: 256,
    label: "256 [kbps]",
  },
  {
    value: 320,
    label: "320 [kbps]",
  },
  {
    value: 384,
    label: "384 [kbps]",
  },
];

export const OUTPUT_RTMP_PIX_FORMAT = [
  {
    value: "YUV420P",
    label: "YUV420P",
  },
];

export const MPEG2VIDEO_PIX_FORMAT = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "YUV420P",
    label: "YUV420P",
  },
  {
    value: "YUV422P",
    label: "YUV422P",
  },
];

export const OPEN_H264_PIX_FORMAT = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "YUV420P",
    label: "YUV420P",
  },
];

export const X264_PIX_FORMAT = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "YUV420P",
    label: "YUV420P",
  },
  {
    value: "YUV422P",
    label: "YUV422P",
  },
  {
    value: "YUV444P",
    label: "YUV444P",
  },
  // {
  //   value: "YUV420P10LE",
  //   label: "YUV420P10LE",
  // },
  // {
  //   value: "YUV422P10LE",
  //   label: "YUV422P10LE",
  // },
];

export const X264_PRESET = [
  {
    value: "ultrafast",
    label: "Ultra fast",
  },
  {
    value: "superfast",
    label: "Super fast",
  },
  {
    value: "veryfast",
    label: "Very fast",
  },
  {
    value: "faster",
    label: "Faster",
  },
  {
    value: "fast",
    label: "Fast",
  },
  {
    value: "medium",
    label: "Medium",
  },
  {
    value: "slow",
    label: "Slow",
  },
  {
    value: "slower",
    label: "Slower",
  },
  {
    value: "veryslow",
    label: "Very slow",
  },
];

export const X264_TUNE = [
  {
    value: "film",
    label: "Film",
  },
  {
    value: "animation",
    label: "Animation",
  },
  {
    value: "grain",
    label: "Grain",
  },
  {
    value: "fastdecode",
    label: "Fast decode",
  },
  {
    value: "zerolatency",
    label: "Zero latency",
  },
];

export const OUTPUT_RTMP_PROFILE = [
  {
    value: "high",
    label: "High",
  },
  {
    value: "main",
    label: "Main",
  },
  {
    value: "baseline",
    label: "Baseline",
  },
];

export const X264_PROFILE = [
  {
    value: "high",
    label: "High",
  },
  // {
  //   value: "high10",
  //   label: "High10",
  // },
  {
    value: "high422",
    label: "High422",
  },
  {
    value: "high444",
    label: "High444",
  },
  {
    value: "main",
    label: "Main",
  },
  {
    value: "baseline",
    label: "Baseline",
  },
];

export const X264_LEVEL = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "1.0",
    label: "1.0",
  },
  {
    value: "1.1",
    label: "1.1",
  },
  {
    value: "1.2",
    label: "1.2",
  },
  {
    value: "1.3",
    label: "1.3",
  },
  {
    value: "2.0",
    label: "2.0",
  },
  {
    value: "2.1",
    label: "2.1",
  },
  {
    value: "2.2",
    label: "2.2",
  },
  {
    value: "3.0",
    label: "3.0",
  },
  {
    value: "3.1",
    label: "3.1",
  },
  {
    value: "3.2",
    label: "3.2",
  },
  {
    value: "4.0",
    label: "4.0",
  },
  {
    value: "4.1",
    label: "4.1",
  },
  {
    value: "4.2",
    label: "4.2",
  },
  {
    value: "5.0",
    label: "5.0",
  },
  {
    value: "5.1",
    label: "5.1",
  },
  {
    value: "5.2",
    label: "5.2",
  },
];

export const MPEG2PROFILE = [
  {
    value: "high",
    label: "High",
  },
  {
    value: "main",
    label: "Main",
  },
];

export const DEFAULT_STREAM = {
  codec: {
    type: SELECT_STREAM_CODEC[0].value,
    resize: SELECT_STREAM_CODEC_RESIZE[0].value,
    fps: SELECT_STREAM_CODEC_FPS[0].value,
    pixFormat: MPEG2VIDEO_PIX_FORMAT[0].value,
    changeGOP: false,
    interlaced: false,
  },
  filters: [],
  selectStream: { streamId: SELECT_STREAM_TYPE[1].value },
  type: STREAMS_TYPE[0].value,
};

export const SELECT_HUB_CHANNELS_RESOLUTION = [
  { value: 1, label: "UHD|4K" },
  { value: 3, label: "HD" },
  { value: 8, label: "SD" },
  { value: 0, label: "Custom" },
];

export const SELECT_MPEG2VIDEO_PRESETS = [
  { value: 1, label: "3840x2160, 16:9 (4K)" },
  { value: 2, label: "1920x1080, 16:9 (Full HD)" },
  { value: 3, label: "1280x720, 16:9 (HD)" },
  { value: 4, label: "720x576, 16:9 (PAL SD 16:9)" },
  { value: 5, label: "720x576, 4:3 (PAL SD 4:3)" },
  { value: 6, label: "720x480, 16:9 (NTSC SD 16:9)" },
  { value: 7, label: "720x480, 4:3 (NTSC SD 4:3)" },
  { value: 0, label: "Custom" },
];

export const SELECT_CUSTOM = {
  value: "custom",
  label: "Custom",
};

export const MPEG2VIDEO_PRESETS = {
  preset4K: {
    width: 3840,
    height: 2160,
    customSar: false,
  },
  presetFullHD: {
    width: 1920,
    height: 1080,
    customSar: false,
  },
  presetHD: {
    width: 1280,
    height: 720,
    customSar: false,
  },
  presetPALSD169: {
    width: 720,
    height: 576,
    customSar: true,
    sarNum: 64,
    sarDen: 45,
  },
  presetPALSD43: {
    width: 720,
    height: 576,
    customSar: true,
    sarNum: 16,
    sarDen: 15,
  },
  presetNTSCSD169: {
    width: 720,
    height: 480,
    customSar: true,
    sarNum: 32,
    sarDen: 27,
  },
  presetNTSCSD43: {
    width: 720,
    height: 480,
    customSar: true,
    sarNum: 8,
    sarDen: 9,
  },
};

export const ORGANIZATION_PROFILES = [
  {
    label: "Broadcaster",
    value: "broadcaster",
  },
  {
    label: "Production company",
    value: "productionCompany",
  },
  {
    label: "Business content distributor",
    value: "businessContentDistributor",
  },
  {
    label: "Technical content distributor",
    value: "technicalContentDistributor",
  },
  {
    label: "Cable network/Digital platform/Telecom",
    value: "cableNetworkDigitalPlatformTelecom",
  },
  {
    label: "Live streaming services provider",
    value: "liveStreamingServicesProvider",
  },
  {
    label: "Systems integrator",
    value: "systemsIntegrator",
  },
  {
    label: "Cinema",
    value: "cinema",
  },
  {
    label: "OTT service provider",
    value: "ottServiceProvider",
  },
  {
    label: "Ads/media broker",
    value: "adsMediaBroker",
  },
  {
    label: "Fast channel platform",
    value: "fastChannelPlatform",
  },
];

export const BUSINESS_ROLES = [
  "Content manager",
  "Business Development and Sales",
  "Distributor",
  "PR/Marketing",
  "Consultant",
  "Owner",
  "CEO",
];

export const TECHNICAL_ROLES = ["Network Operations Center", "Headend", "Technical support", "Field technician"];

export const SEO_STATUS = {
  VERIFICATION: "verification",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  PRIVATE: "private",
};

export const VALIDATION_STATUS = {
  VALIDATING: "validating",
  SUCCESS: "success",
  ERROR: "error",
};

export const VALID_IMAGE_FORMATS = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/x-ms-bmp",
  "image/svg",
  "image/svg+xml",
];

export const NODE_TYPES = {
  USER: "user",
  CLOUD: "cloud",
  SHARED: "sharedNode",
};

export const CLOUD_NODE_STATES = {
  RUNNING: "running",
  STOPPED: "stopped",
  REMOVED: "removed",
};

export const TRIAL_NODE_TYPE = "trial";

export const SELECT_NODE_TYPES = [
  {
    label: "TRIAL",
    value: "trial",
  },
  {
    label: "MEDIUM",
    value: "t3.medium",
  },
  {
    label: "LARGE",
    value: "t3.large",
  },
  {
    label: "XLARGE",
    value: "t3.xlarge",
  },
  {
    label: "2XLARGE",
    value: "t3.2xlarge",
  },
];

export const NODES_TRAFFIC_TYPE = {
  ALL: "allNodes",
  ACTIVE: "activeNodes",
};

export const SELECT_AWS_REGIONS = [
  {
    label: "US East (Ohio)",
    value: "us-east-2",
  },
  {
    label: "US East (N. Virginia)",
    value: "us-east-1",
  },
  {
    label: "US West (N. California)",
    value: "us-west-1",
  },

  {
    label: "US West (Oregon)",
    value: "us-west-2",
  },
  {
    label: "Asia Pacific (Mumbai)",
    value: "ap-south-1",
  },
  {
    label: "Asia Pacific (Osaka)",
    value: "ap-northeast-3",
  },
  {
    label: "Asia Pacific (Seoul)",
    value: "ap-northeast-2",
  },
  {
    label: "Asia Pacific (Singapore)",
    value: "ap-southeast-1",
  },
  {
    label: "Asia Pacific (Sydney)",
    value: "ap-southeast-2",
  },
  {
    label: "Asia Pacific (Tokyo)",
    value: "ap-northeast-1",
  },
  {
    label: "Canada (Central)",
    value: "ca-central-1",
  },
  {
    label: "Europe (Frankfurt)",
    value: "eu-central-1",
  },
  {
    label: "Europe (Ireland)",
    value: "eu-west-1",
  },
  {
    label: "Europe (London)",
    value: "eu-west-2",
  },
  {
    label: "Europe (Paris)",
    value: "eu-west-3",
  },
  {
    label: "Europe (Stockholm)",
    value: "eu-north-1",
  },
  {
    label: "South America (São Paulo)",
    value: "sa-east-1",
  },
];

export const INPUTS_STATUS_IDS = {
  MAIN: 1,
  BACKUP: 2,
  EMERGENCY: -1,
};

export const P2_CODECS_STATS = {
  VIDEO: -1,
  AUDIO: 0,
};

export const INPUT_CHANNEL_STATE = {
  CLOSED: "CLOSED",
  CONNECTING: "CONNECTING",
  CONNECTED: "CONNECTED",
  RECONNECTING: "RECONNECTING",
  CLOSING: "CLOSING",
  ERROR: "ERROR",
};

export const ENGINE_TYPES = {
  DIRECT: "direct",
  TRANSCODING: "transcoding",
  PLAYOUT: "playout",
};

export const SELECT_GLOBAL_STREAM_CODEC_FPS = [
  {
    value: "23.98",
    label: "23.98 [fps]",
  },
  {
    value: "24",
    label: "24 [fps]",
  },
  {
    value: "25",
    label: "25 [fps]",
  },
  {
    value: "29.97",
    label: "29.97 [fps]",
  },
  {
    value: "30",
    label: "30 [fps]",
  },
  {
    value: "47.95",
    label: "47.95 [fps]",
  },
  {
    value: "48",
    label: "48 [fps]",
  },
  {
    value: "50",
    label: "50 [fps]",
  },
  {
    value: "59.94",
    label: "59.94 [fps]",
  },
  {
    value: "60",
    label: "60 [fps]",
  },
  {
    value: "95.90",
    label: "95.90 [fps]",
  },
  {
    value: "96",
    label: "96 [fps]",
  },
  {
    value: "100",
    label: "100 [fps]",
  },
  {
    value: "119.88",
    label: "119.88 [fps]",
  },
  {
    value: "120",
    label: "120 [fps]",
  },
];

export const GLOBAL_VIDEO_FORMATS = [
  {
    value: "YUV420P",
    label: "YUV420P",
  },
  {
    value: "YUV422P",
    label: "YUV422P",
  },
  {
    value: "YUV444P",
    label: "YUV444P",
  },
  {
    value: "YUV420P10LE",
    label: "YUV420P10LE",
  },
  {
    value: "YUV422P10LE",
    label: "YUV422P10LE",
  },
  {
    value: "UYVY422",
    label: "UYVY422",
  },
  {
    value: "BGRA",
    label: "BGRA",
  },
  {
    value: "BGR0",
    label: "BGR0",
  },
  {
    value: "RGBA",
    label: "RGBA",
  },
  {
    value: "RGB0",
    label: "RGB0",
  },
];

export const GLOBAL_VIDEO_RESIZE = [
  {
    value: "pad",
    label: "Pad",
  },
  {
    value: "crop",
    label: "Crop",
  },
  {
    value: "stretch",
    label: "Stretch",
  },
  {
    value: "fitWidth",
    label: "Fit Width",
  },
  {
    value: "fitHeight",
    label: "Fit Height",
  },
];

export const DEFAULT_GLOBAL_VIDEO = {
  customSar: false,
  fps: SELECT_GLOBAL_STREAM_CODEC_FPS[2].value,
  height: MPEG2VIDEO_PRESETS.presetFullHD.height,
  pixFormat: GLOBAL_VIDEO_FORMATS[0].value,
  presetId: 2,
  resize: GLOBAL_VIDEO_RESIZE[0].value,
  width: MPEG2VIDEO_PRESETS.presetFullHD.width,
};

export const GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS = [
  {
    value: "FLT",
    label: "FLT",
  },
  {
    value: "FLTP",
    label: "FLTP",
  },
  {
    value: "S16",
    label: "S16",
  },
  {
    value: "S16P",
    label: "S16P",
  },
];

export const GLOBAL_CODEC_AUDIO_SAMPLE_RATE = [
  {
    value: "44100",
    label: "44.1kHz",
  },
  {
    value: "48000",
    label: "48kHz",
  },
];

export const DEFAULT_SWITCH_SETTINGS = {
  backupSwitchMsecs: 500,
  backupReturnMsecs: 5000,
};

export const EMERGENCY_MODE = {
  NONE: "none",
  STATIC: "static",
  LOOP: "loop",
};

export const DEFAULT_EMERGENCY_MODE = {
  emergencyMode: EMERGENCY_MODE.NONE,
  emergencyReturnMsecs: 5000,
  emergencySwitchMsecs: 1000,
};

export const DIRECT_ENGINE_OUTPUTS_PREFIX = "outputs";
export const PLAYOUT_ENGINE_OUTPUTS_PREFIX = "outputs";
export const OUTPUT_TO_FILE_TYPE = "outFile";

export const SELECT_TRANSCODING_CODEC_AUDIO = [
  {
    value: "Mpeg2audio",
    label: "MPEG-2 Audio Layer II",
  },
  {
    value: "Aac",
    label: "AAC",
  },
];

export const SELECT_TRANSCODING_RTMP_CODEC_AUDIO = [
  {
    value: "Aac",
    label: "AAC",
  },
];

export const SELECT_TRANSCODING_VIDEO_CODEC_VALUES = ["Mpeg2video", "OpenH264", "X264"];

export const SELECT_TRANSCODING_RTMP_VIDEO_CODECS_VALUES = ["OpenH264", "X264"];

export const NV_265_LEVEL_SELECTOR = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "1.0",
    label: "1.0",
  },
  {
    value: "2.0",
    label: "2.0",
  },
  {
    value: "2.1",
    label: "2.1",
  },
  {
    value: "3.0",
    label: "3.0",
  },
  {
    value: "3.1",
    label: "3.1",
  },
  {
    value: "4.0",
    label: "4.0",
  },
  {
    value: "4.1",
    label: "4.1",
  },
  {
    value: "5.0",
    label: "5.0",
  },
  {
    value: "5.1",
    label: "5.1",
  },
  {
    value: "5.2",
    label: "5.2",
  },
  {
    value: "6.0",
    label: "6.0",
  },
  {
    value: "6.1",
    label: "6.1",
  },
  {
    value: "6.2",
    label: "6.2",
  },
];

export const NV_265_RATE_CONTROL_SELECTOR = [
  {
    value: "vbr",
    label: "VBR",
  },
  {
    value: "cbr",
    label: "CBR",
  },
  {
    value: "cbr_hq",
    label: "CBR HQ",
  },
  {
    value: "vbr_hq",
    label: "VBR HQ",
  },
];

export const NV_265_TIER_SELECTOR = [
  {
    value: "high",
    label: "HIGH",
  },
  {
    value: "main",
    label: "MAIN",
  },
];

export const NV_265_PRESET_SELECTOR = [
  {
    value: "default",
    label: "DEFAULT",
  },
  {
    value: "slow",
    label: "SLOW",
  },
  {
    value: "medium",
    label: "MEDIUM",
  },
  {
    value: "fast",
    label: "FAST",
  },
  {
    value: "hp",
    label: "HP",
  },
  {
    value: "hq",
    label: "HQ",
  },
  {
    value: "p1",
    label: "P1",
  },
  {
    value: "p2",
    label: "P2",
  },
  {
    value: "p3",
    label: "P3",
  },
  {
    value: "p4",
    label: "P4",
  },
  {
    value: "p5",
    label: "P5",
  },
  {
    value: "p6",
    label: "P6",
  },
  {
    value: "p7",
    label: "P7",
  },
];

export const NV_264_CODER_SELECTOR = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "cabac",
    label: "CABAC",
  },
  {
    value: "cavlc",
    label: "CAVLC",
  },
];

export const NV_264_LEVEL_SELECTOR = [
  {
    value: "automatic",
    label: "Automatic",
  },
  {
    value: "1.0",
    label: "1.0",
  },
  {
    value: "1.0b",
    label: "1.0b",
  },
  {
    value: "1.1",
    label: "1.1",
  },
  {
    value: "1.2",
    label: "1.2",
  },
  {
    value: "1.3",
    label: "1.3",
  },
  {
    value: "2.0",
    label: "2.0",
  },
  {
    value: "2.1",
    label: "2.1",
  },
  {
    value: "2.2",
    label: "2.2",
  },
  {
    value: "3.0",
    label: "3.0",
  },
  {
    value: "3.1",
    label: "3.1",
  },
  {
    value: "3.2",
    label: "3.2",
  },
  {
    value: "4.0",
    label: "4.0",
  },
  {
    value: "4.1",
    label: "4.1",
  },
  {
    value: "4.2",
    label: "4.2",
  },
  {
    value: "5.0",
    label: "5.0",
  },
  {
    value: "5.1",
    label: "5.1",
  },
  {
    value: "5.2",
    label: "5.2",
  },
  {
    value: "6.0",
    label: "6.0",
  },
  {
    value: "6.1",
    label: "6.1",
  },
  {
    value: "6.2",
    label: "6.2",
  },
];

export const NV_264_PROFILE_SELECTOR = [
  {
    value: "high",
    label: "HIGH",
  },
  {
    value: "main",
    label: "MAIN",
  },
];

export const NV_264_RATE_CONTROL_SELECTOR = [
  {
    value: "vbr",
    label: "VBR",
  },
  {
    value: "cbr",
    label: "CBR",
  },
  {
    value: "cbr_hq",
    label: "CBR HQ",
  },
  {
    value: "vbr_hq",
    label: "VBR HQ",
  },
];

export const NV_264_PRESET_SELECTOR = [
  {
    value: "default",
    label: "DEFAULT",
  },
  {
    value: "slow",
    label: "SLOW",
  },
  {
    value: "medium",
    label: "MEDIUM",
  },
  {
    value: "fast",
    label: "FAST",
  },
  {
    value: "hp",
    label: "HP",
  },
  {
    value: "hq",
    label: "HQ",
  },
  {
    value: "p1",
    label: "P1",
  },
  {
    value: "p2",
    label: "P2",
  },
  {
    value: "p3",
    label: "P3",
  },
  {
    value: "p4",
    label: "P4",
  },
  {
    value: "p5",
    label: "P5",
  },
  {
    value: "p6",
    label: "P6",
  },
  {
    value: "p7",
    label: "P7",
  },
];

export const TRANSCODING_OPEN_H264_PIX_FORMAT = [
  {
    value: "YUV420P",
    label: "YUV420P",
  },
];

export const TRANSCODING_NVIDIA_PIX_FORMAT = [
  {
    value: "YUV420P",
    label: "YUV420P",
  },
];

export const TRANSCODING_MPEG2VIDEO_PIX_FORMAT = [
  {
    value: "YUV420P",
    label: "YUV420P",
  },
  {
    value: "YUV422P",
    label: "YUV422P",
  },
];

export const TRANSCODING_X264_PIX_FORMAT = [
  {
    value: "YUV420P",
    label: "YUV420P",
  },
  {
    value: "YUV422P",
    label: "YUV422P",
  },
  {
    value: "YUV444P",
    label: "YUV444P",
  },
];

export const ACC_AUDIO_SAMPLE_RATE_FORMATS = [
  {
    value: "S16",
    label: "S16",
  },
];

export const PROCESSING_VIDEO_FORMATS = [
  {
    value: "YUV420P",
    label: "YUV420P",
  },
  {
    value: "YUV422P",
    label: "YUV422P",
  },
  {
    value: "YUV444P",
    label: "YUV444P",
  },
  {
    value: "UYVY422",
    label: "UYVY422",
  },
  {
    value: "BGRA",
    label: "BGRA",
  },
  {
    value: "BGR0",
    label: "BGR0",
  },
  {
    value: "RGBA",
    label: "RGBA",
  },
  {
    value: "RGB0",
    label: "RGB0",
  },
];

export const SELECTOR_EMERGENCY_MODE = [
  {
    label: "Black screen",
    value: "none",
  },
  {
    label: "Static screen",
    value: "static",
  },
  {
    label: "Looped video",
    value: "loop",
  },
];

export const MAIN_THEME = {
  DARK: "dark",
  LIGHT: "light",
};

export const SELECTOR_SDI_AUDIO_CHANNELS = [
  {
    label: 2,
    value: 2,
  },
  {
    label: 8,
    value: 8,
  },
];

export const SELECTOR_NDI_AUDIO_CHANNELS = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
];

export const SELECTOR_SDI_VIDEO_SOURCE = [
  SELECT_DEFAULT_AUTO,
  {
    label: "SDI",
    value: "SDI",
  },
  {
    label: "HDMI",
    value: "HDMI",
  },
  {
    label: "OpticalSDI",
    value: "OpticalSDI",
  },
  {
    label: "Component",
    value: "Component",
  },
  {
    label: "Composite",
    value: "Composite",
  },
  {
    label: "SVideo",
    value: "SVideo",
  },
];

export const SELECTOR_SDI_AUDIO_SOURCE = [
  SELECT_DEFAULT_AUTO,
  {
    label: "Embedded",
    value: "Embedded",
  },
  {
    label: "AESEBU",
    value: "AESEBU",
  },
  {
    label: "Analog",
    value: "Analog",
  },
  {
    label: "AnalogXLR",
    value: "AnalogXLR",
  },
  {
    label: "AnalogRCA",
    value: "AnalogRCA",
  },
  {
    label: "Microphone",
    value: "Microphone",
  },
];

export const NDI_AUDIO_SAMPLE_FORMAT = {
  value: "S16",
  label: "S16",
};

export const FILE_TIME_SPAN_OPTIONS = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 60, label: "60" },
  { value: 90, label: "90" },
  { value: 120, label: "120" },
  { value: 180, label: "180" },
];

export const NODE_OS = {
  WINDOWS: "Windows",
  LINUX: "Linux",
};

export const NODE_PLAYOUT_PLAYLIST_TYPE = {
  FILE: "File",
  AD_BREAK: "AdBreak",
  LIVE: "Live",
  PLACEHOLDER: "Placeholder",
};
