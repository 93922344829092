import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import moment from "moment";
import { createSelector } from "reselect";

export const actions = {
  SET_PLAYOUT_PLAYLIST: createAction("PLAYOUT_PLAYLIST/SET_PLAYOUT_PLAYLIST"),
  UPDATE_PLAYOUT_PLAYLIST: createAction("PLAYOUT_PLAYLIST/UPDATE_PLAYOUT_PLAYLIST"),
  CLEAR_PLAYOUT_PLAYLIST: createAction("PLAYOUT_PLAYLIST/CLEAR_PLAYOUT_PLAYLIST"),
  CLEAR_PLAYOUT_PLAYLISTS: createAction("PLAYOUT_PLAYLIST/CLEAR_PLAYOUT_PLAYLISTS"),
};

const defaultState = new Map({});

const reducer = handleActions(
  {
    [actions.SET_PLAYOUT_PLAYLIST]: (state, { payload }) => {
      return state.set(payload.channelId, payload.list);
    },
    [actions.CLEAR_PLAYOUT_PLAYLIST]: (state, { payload }) => {
      return state.delete(payload?.channelId);
    },
    [actions.CLEAR_PLAYOUT_PLAYLISTS]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getParams: (state, params) => params,
  getPlayoutPlaylist: (state, channelId) => {
    const playoutPlaylist = state.getIn(["nodePlayoutPlaylist", channelId]);

    return playoutPlaylist;
  },
};

const formatDuration = (ms) => {
  if (!ms && ms !== 0) {
    return "unknown";
  }

  const duration = moment.duration(ms);
  const hours = String(duration.hours()).padStart(2, "0");
  const minutes = String(duration.minutes()).padStart(2, "0");
  const seconds = String(duration.seconds()).padStart(2, "0");
  const millis = String(duration.milliseconds()).padStart(3, "0");

  return `${hours}:${minutes}:${seconds}.${millis}`;
};

export const MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR = createSelector([selectors.getPlayoutPlaylist], (playoutPlaylist) => {
  const parsedPlayoutPlaylist =
    playoutPlaylist &&
    playoutPlaylist.map((item) => {
      const formattedDuration = formatDuration(item?.duration);
      const formattedTcIn = formatDuration(item?.tcIn);
      const formattedTcOut = formatDuration(item?.tcOut);
      const fileName = item?.videoFilePath.split("\\").pop();

      return {
        ...item,
        time: item.start && moment.utc(item.start).format("HH:mm:ss.SSS"),
        date: item.start && moment.utc(item.start).format("YYYY-MM-DD"),
        formattedDuration,
        formattedTcIn,
        formattedTcOut,
        fileName,
      };
    });

  return parsedPlayoutPlaylist;
});

export default reducer;
