import React from "react";
import { Card, Table, Tooltip, Tag } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR } from "../../ducks/nodePlayoutPlaylist";
import themeColors from "../../lib/style/theme";
import { NODE_PLAYOUT_PLAYLIST_TYPE } from "../../lib/utils/constants";

const columns = [
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.date" defaultMessage="DATE" />,
    dataIndex: "time",
    key: "time",
    render: (time, record) => (
      <Tooltip placement="top" title={record?.date}>
        <span>{time}</span>
      </Tooltip>
    ),
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.name" defaultMessage="NAME" />,
    dataIndex: "clipName",
    key: "clipName",
    render: (clipName, record) => (
      <span>
        <StyledSpan>{record?.clipName}</StyledSpan>
        {record?.fileName ? ` (${record.fileName})` : ""}
      </span>
    ),
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.type" defaultMessage="TYPE" />,
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (type) => {
      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.FILE) {
        return (
          <Tag bordered={false} color={themeColors.defaultBlueColor}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.AD_BREAK) {
        return (
          <Tag bordered={false} color={themeColors.orange}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE) {
        return (
          <Tag bordered={false} color={themeColors.red}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.PLACEHOLDER) {
        return (
          <Tag bordered={false} color={themeColors.maritime}>
            {type}
          </Tag>
        );
      }

      return <Tag bordered={false}>{type}</Tag>;
    },
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="STATUS" />,
    dataIndex: "itemStatus",
    key: "itemStatus",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.extAudioStatus" defaultMessage="EXT. AUDIO" />,
    dataIndex: "extAudioStatus",
    key: "extAudioStatus",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.duration" defaultMessage="DURATION" />,
    dataIndex: "formattedDuration",
    key: "formattedDuration",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.tcIn" defaultMessage="TC IN" />,
    dataIndex: "formattedTcIn",
    key: "formattedTcIn",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.tcOut" defaultMessage="TC OUT" />,
    dataIndex: "formattedTcOut",
    key: "formattedTcOut",
    align: "center",
  },
];

const NodePlayoutPlaylists = () => {
  const { id } = useParams();
  const playoutPlaylist = useSelector((state) => MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR(state, +id));

  return (
    <Card>
      <Table
        pagination={false}
        dataSource={playoutPlaylist}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <pre>{JSON.stringify({ cgEvents: record?.cgEvents }, null, 2)}</pre>,
          rowExpandable: (record) => !!record?.cgEvents,
          expandRowByClick: true,
        }}
        rowKey={(record) => record?.guid}
      />
    </Card>
  );
};

const StyledSpan = styled.span`
  color: ${themeColors.orange};
`;

export default NodePlayoutPlaylists;
